import axios from 'axios';
import router from '../router';

const state = {
  products: {}
};

const mutations = {
    SET_PRODUCTS(state, products) {
      state.products = products;
    },
  };

const actions = {
    async fetchproducts({ commit }) {
      const userToken = localStorage.getItem('userToken'); // Retrieve token from localStorage
      if (!userToken) {
          // Redirect to login page if token is not found
          router.push({ name: 'login' });
          return;
      }
      try {
        const response = await axios('https://test.sidemageren.dk/api/products', {
                headers: { Authorization: `Bearer ${userToken}` },
            });
        const products = response.data.products;
        commit('SET_PRODUCTS', products);
      } catch (error) {
          if (error.response && error.response.status === 401) {
            // Unauthorized, redirect to login page
            router.push({ name: 'login' });
        } else {
            console.error('Failed to fetch products:', error);
        }
      }
    },
  };

const getters = {
// Product getters
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};