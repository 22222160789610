import axios from 'axios';
import router from '../router';

const state = {
  locations: {}
};

const mutations = {
    SET_LOCATIONS(state, locations) {
      state.locations = locations;
    },
    DELETE_LOCATION(state, locationId) {
      state.locations = state.locations.filter(location => location.id !== locationId);
    },
    UPDATE_LOCATION(state, updatedLocation) {
      const index = state.locations.findIndex(location => location.id === updatedLocation.id);
      if (index !== -1) {
        // Update the location object in the array with the new data
        state.locations.splice(index, 1, updatedLocation);
      }
    },
    CREATE_LOCATION(state, newLocation) {
      // Add the new location to the locations array
      state.locations.push(newLocation);
      //state.locations = state.locations.filter(location => location.id !== locationId);
    }
  };

const actions = {
    async fetchLocations({ commit }) {
      const userToken = localStorage.getItem('userToken'); // Retrieve token from localStorage
      if (!userToken) {
          // Redirect to login page if token is not found
          router.push({ name: 'login' });
          return;
      }
      try {
        const response = await axios('https://test.sidemageren.dk/api/locations', {
                headers: { Authorization: `Bearer ${userToken}` },
            });
        const locations = response.data.locations;
        commit('SET_LOCATIONS', locations);
      } catch (error) {
        if (error.response && error.response.status === 401) {
            // Unauthorized, redirect to login page
            router.push({ name: 'login' });
        } else {
            console.error('Failed to fetch locations:', error);
        }
      }
    },
    async updateLocation({ commit, state }, updatedLocation) {
      const userToken = localStorage.getItem('userToken'); // Retrieve token from localStorage
      if (!userToken) {
          // Redirect to login page if token is not found
          router.push({ name: 'login' });
          return;
      }
      try {
        // Make API call to update location details
        const response = await axios.put(`https://test.sidemageren.dk/api/locations/${updatedLocation.id}`, updatedLocation, {
          headers: { Authorization: `Bearer ${userToken}` },
        });
  
        // Optionally, commit a mutation to update the state in the store
        commit('UPDATE_LOCATION', updatedLocation);
  
        // Optionally, return data from the API response
        return response.data; // Assuming the API returns the updated location data
      } catch (error) {
        if (error.response && error.response.status === 401) {
            // Unauthorized, redirect to login page
            router.push({ name: 'login' });
        } else {
            console.error('Error updating location:', error);
            throw error; // Optionally, rethrow the error to handle it elsewhere
        }
      }
    },
    async createLocation({ commit, state }, newLocation) {
      const userToken = localStorage.getItem('userToken'); // Retrieve token from localStorage
      if (!userToken) {
          // Redirect to login page if token is not found
          router.push({ name: 'login' });
          return;
      }
      try {
        // Make API call to update location details
        const response = await axios.post(`https://test.sidemageren.dk/api/locations`, newLocation, {
          headers: { Authorization: `Bearer ${userToken}` },
        });
  
        // Optionally, commit a mutation to update the state in the store
        commit('CREATE_LOCATION', newLocation);

        // Optionally, return data from the API response
        return response.data; // Assuming the API returns the updated location data
      } catch (error) {
        if (error.response && error.response.status === 401) {
            // Unauthorized, redirect to login page
            router.push({ name: 'login' });
        } else {
            console.error('Error creating location:', error);
            throw error; // Optionally, rethrow the error to handle it elsewhere
        }
      }
    },
    async deleteLocation({ commit }, locationId) {
      const userToken = localStorage.getItem('userToken'); // Retrieve token from localStorage
      if (!userToken) {
          // Redirect to login page if token is not found
          router.push({ name: 'login' });
          return;
      }
      try {
        // Make API call to delete location with given ID
        await axios.delete(`https://test.sidemageren.dk/api/locations/${locationId}`, { 
            headers: { Authorization: `Bearer ${userToken}` },
      });
        
        // Optionally, commit a mutation to update the state in the store
        commit('DELETE_LOCATION', locationId);
        
        // Optionally, return data from the API response
        return { success: true, message: 'Location deleted successfully' };
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // Unauthorized, redirect to login page
          router.push({ name: 'login' });
        } else {
          console.error('Error deleting location:', error);
          throw error; // Optionally, rethrow the error to handle it elsewhere
        }
      }
    }
  };

const getters = {
// Locations getters
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};