<template>
  <div id="app">
    <template v-if="isLoggedIn">
      <AuthenticatedLayout>
        <!-- <router-view></router-view> Render authenticated content (e.g., Dashboard) -->
        
      </AuthenticatedLayout>
    </template>
    <template v-else>
      <LoginLayout>
        <!-- Render login-related content (e.g., Login form) -->
        <router-view></router-view>
      </LoginLayout>
    </template>
  </div>
</template>

<script>
import AuthenticatedLayout from './components/AuthenticatedLayout.vue';
import LoginLayout from './components/LoginLayout.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    AuthenticatedLayout,
    LoginLayout
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn']),
    user() {
      // Retrieve user from localStorage if available
      return localStorage.getItem('realname') || 'No Name'; // Provide a default value if realname is null
    }
  }
};
</script>