import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue';

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: HomeView,
    beforeEnter: (to, from, next) => {
      const userToken = localStorage.getItem('userToken');
      if (userToken) {
        next();
      } else {
        next('/login'); // Redirect to login if user token doesn't exist
      }
    }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/products',
    name: 'products',
    component: () => import(/* webpackChunkName: "products" */ '../views/ProductsView.vue')
  },
  {
    path: '/locations',
    name: 'locations',
    component: () => import(/* webpackChunkName: "locations" */ '../views/LocationsView.vue')
  },
  {
    path: '/customers',
    name: 'customers',
    component: () => import(/* webpackChunkName: "customers" */ '../views/CustomersView.vue')
  },
  {
    path: '/timeregistration',
    name: 'timeregistration',
    component: () => import(/* webpackChunkName: "timeregistration" */ '../views/TimesView.vue')
  }
  ,
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "timeregistration" */ '../views/LoginView.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
