<template>
  <div style="margin-top: 160px;">
    <LoginLayout />
  </div>
</template>

<script>
import LoginLayout from '@/components/LoginLayout.vue';

export default {
  components: {
    LoginLayout
},
};
</script>