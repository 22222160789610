<template>
  <div>
    <div v-if="isLoggedIn && Object.keys(dashboardData).length">
      
      <div class="dashboard pa-8">
        <h2>Welcome, {{ user }}</h2>
        <h1>Dashboard</h1>
        <v-container class="pl-0">
          <v-row class="pa-16 pl-0 pt-0">
            <v-col v-for="(count, key) in dashboardData" :key="key" cols="auto">
              <v-card class="mx-auto" width="344" :color="color" variant="elevated">
                <v-card-item>
                  <div>
                    <div class="text-overline mb-1">{{ key }}</div>
                    <div class="text-h6 mb-1">{{ count }} {{ key }}</div>
                  </div>
                </v-card-item>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <div v-else>
      <p v-if="isLoggedIn">Loading dashboard...</p>
      <p v-else>Please log in to access the dashboard</p>
    </div>
  </div>
</template>

<script setup>
  import { ref, onMounted, computed } from 'vue';
  import { useStore } from 'vuex';

  const store = useStore();
  const dashboardData = ref({});
  const color = ref('indigo-darken-4')
  const user = localStorage.getItem('realname');

  // console.log("Inside script setup");

  onMounted(async () => {
    try {
      // console.log("Inside onMounted()");
      if (store.getters['auth/isLoggedIn']) {
        await store.dispatch('dashboard/fetchCounts');
        dashboardData.value = store.state.dashboard.dashboardData;
        //console.log('User:', user.value);
        //console.log('Entire Vuex store:', store.state);
      }
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
    }
  });

  const isLoggedIn = computed(() => store.getters['auth/isLoggedIn']);
  
</script>