import axios from 'axios';
import router from '../router';

// In dashboard.js
const state = {
    dashboarddata: {}
};

const mutations = {
    SET_DASHBOARD_DATA(state, { counts }) { // Update mutation name
        state.dashboardData = counts; // Update state property name
    }
};

const actions = {
    async fetchCounts({ commit }) {
        const userToken = localStorage.getItem('userToken'); // Retrieve token from localStorage
        if (!userToken) {
            // Redirect to login page if token is not found
            router.push({ name: 'login' });
            return;
        }
        
        try {
            const response = await axios.get('https://test.sidemageren.dk/api/dashboard', {
                headers: { Authorization: `Bearer ${userToken}` },
            });
            const counts = response.data.dashboarddata;
            commit('SET_DASHBOARD_DATA', { counts });
        } catch (error) {
            if (error.response && error.response.status === 401) {
                // Unauthorized, redirect to login page
                router.push({ name: 'login' });
            } else {
                console.error('Failed to fetch counts:', error);
                throw error;
            }
        }
    }
};

const getters = {
    // Dashboard getters
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

// Similar structure for other modules (locations.js, customers.js, etc.)
  