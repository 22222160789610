import { createStore } from 'vuex';

import auth from './auth';
import timesheets from './timesheets';
import dashboard from './dashboard';
import locations from './locations';
import customers from './customers';
import products from './products';
import users from './users';



export default createStore({
  modules: {
    auth,
    timesheets,
    dashboard,
    locations,
    customers,
    products,
    users,
  }
});