import axios from 'axios';
import router from '../router';

const state = {
  customers: {}
};

const mutations = {
    SET_CUSTOMERS(state, customers) {
      state.customers = customers;
    },
  };

const actions = {
    async fetchCustomers({ commit }) {
      const userToken = localStorage.getItem('userToken'); // Retrieve token from localStorage
      if (!userToken) {
          // Redirect to login page if token is not found
          router.push({ name: 'login' });
          return;
      }
      try {
        const response = await axios('https://test.sidemageren.dk/api/customers', {
                headers: { Authorization: `Bearer ${userToken}` },
            });
        const customers = response.data.customers;
        commit('SET_CUSTOMERS', customers);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // Unauthorized, redirect to login page
          router.push({ name: 'login' });
        } else {
          console.error('Failed to fetch customers:', error);
        }
      }
    },
  };

const getters = {
// Locations getters
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};