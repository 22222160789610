import axios from 'axios';
import router from '../router';

const state = {
  times: {}
};

const mutations = {
    SET_TIMES(state, times) {
      state.times = times;
    },
  };

const actions = {
    async fetchTimes({ commit }) {
      const userToken = localStorage.getItem('userToken'); // Retrieve token from localStorage
      if (!userToken) {
          router.push({ name: 'login' });
          return;
      }
      try {
        const response = await axios('https://test.sidemageren.dk/api/timesheets', {
                headers: { Authorization: `Bearer ${userToken}` },
            });
        const times = response.data.timesheets;
        commit('SET_TIMES', times);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          router.push({ name: 'login' });
        } else {
          console.error('Failed to fetch timesheets:', error);
        }
      }
    },
  };

const getters = {
// times getters
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};