import axios from 'axios';

// Define state
const state = {
  id: 0,
  username: '',
  realname: '',
  role: 0,
  token: localStorage.getItem('userToken') || '',
};

// Define mutations
const mutations = {
  SET_USER(state, { id, username, realname, role }) {
    state.id = id;
    state.username = username;
    state.realname = realname;
    state.role = role;

    // Save user data to local storage
    localStorage.setItem('userId', id);
    localStorage.setItem('username', username);
    localStorage.setItem('realname', realname);
    localStorage.setItem('role', role);
  },
  SET_TOKEN(state, token) {
    state.token = token;
    localStorage.setItem('userToken', token);
  },
  CLEAR_USER(state) {
    state.id = null;
    state.username = '';
    state.realname = '';
    state.role = 0;
    state.token = '';


    // Clear user data from local storage
    localStorage.removeItem('userId');
    localStorage.removeItem('username');
    localStorage.removeItem('realname');
    localStorage.removeItem('role');
    localStorage.removeItem('userToken');
  },
};

// Define actions
const actions = {
  async login({ commit }, { username, password }) {
    try {
      const response = await axios.post('https://test.sidemageren.dk/api/auth/login', { username, password });
      console.log(response.data);
      const { id, token, realname, role } = response.data;
      commit('SET_USER', { id, username, realname, role });
      commit('SET_TOKEN', token);
      
    } catch (error) {
      console.error('Error logging in:', error);
      throw error;
    }
  },
  logout({ commit }) {
    commit('CLEAR_USER');
  },
};

const getters = {
  isLoggedIn: state => !!state.token,
  user: state => state.realname,
};

// Export the module
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};